import { graphql } from "gatsby"
import Image from "gatsby-image"
import { RichText } from "prismic-reactjs"
import { FunctionComponent } from "react"
import { ServiceProviderQuery } from "../../graphql-types"
import ContactInfo from "../components/ContactInfo"
import FilterButton from "../components/FilterButton"
import GoBackButton from "../components/GoBackButton"
import Layout from "../components/Layout"
import Map from "../components/Map"
import ServiceList from "../components/ServiceList"
import { Service } from "../types"
import htmlSerializer from "../utils/html-serializer"
import i18n, { simpleLang } from "../utils/i18n"

export const query = graphql`
  query ServiceProvider($uid: String!, $lang: String!, $id: String!) {
    prismic {
      serviceprovider(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
          id
        }
        name
        description
        address
        email
        location
        phonenumber
        webpage
        image
        imageSharp {
          id
          picture: childImageSharp {
            main: fluid {
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
      }
      allServices(where: { serviceprovider: $id }, lang: $lang, first: 100) {
        edges {
          node {
            name
            summary
            location
            activities {
              activity {
                ... on PRISMIC_Activity {
                  name
                  icon
                  _meta {
                    id
                    uid
                  }
                }
              }
            }
            profiles {
              profile {
                ... on PRISMIC_Travellerprofile {
                  name
                  icon
                  _meta {
                    id
                    uid
                  }
                }
              }
            }
            imageSharp {
              id
              childImageSharp {
                fluid(maxWidth: 1024, maxHeight: 640, quality: 80) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            image
            _meta {
              lang
              uid
              type
            }
          }
        }
      }
    }
  }
`

type ServiceProviderProps = {
  data: ServiceProviderQuery
}

const ServiceProvider: FunctionComponent<ServiceProviderProps> = props => {
  const serviceProvider = props.data.prismic.serviceprovider
  const servicesQueryResult = props.data.prismic.allServices

  if (!serviceProvider) return null

  const serviceProviderName = serviceProvider.name[0].text
  const serviceProviderDescription = serviceProvider.description[0].text

  const serviceProviderImageUrl = serviceProvider.imageSharp?.picture?.main!.src

  const services: Service[] =
    servicesQueryResult.edges?.map(edge => ({
      type: "service",
      uid: edge?.node._meta.uid as string,
      lang: edge?.node._meta.lang as string,
      title: edge?.node.name[0]?.text,
      activities:
        edge?.node.activities!.map(activity => ({
          id: activity.activity?._meta.id as string,
          uid: activity.activity?._meta.uid as string,
          name: activity.activity?.name[0]?.text,
          iconName: activity.activity?.icon as string,
        })) || [],
      profiles:
        edge?.node.profiles!.map(profile => ({
          id: profile.profile?._meta.id as string,
          uid: profile.profile?._meta.uid as string,
          name: profile.profile?.name[0]?.text,
          iconName: profile.profile?.icon as string,
        })) || [],
      image: {
        url: edge?.node.imageSharp?.childImageSharp?.fluid?.src as string,
      },
      serviceProvider: {
        type: "serviceprovider",
        lang: serviceProvider._meta.lang,
        id: serviceProvider._meta.id,
        uid: serviceProvider._meta.uid,
        name: serviceProviderName,
        description: serviceProvider.description,
      },
    })) || []

  const lang = simpleLang(serviceProvider._meta.lang)

  return (
    <Layout
      title={serviceProviderName}
      description={serviceProviderDescription}
      imageUrl={serviceProviderImageUrl}
      lang={lang}
    >
      <div>
        <div className="m-4">
          <h2>{serviceProviderName}</h2>

          {serviceProvider.imageSharp && (
            <Image
              className="mt-4"
              fluid={serviceProvider.imageSharp?.picture?.main!}
            />
          )}

          <RichText
            render={serviceProvider.description}
            htmlSerializer={htmlSerializer}
          />

          <div className="my-4">
            {serviceProvider.phonenumber && (
              <ContactInfo
                name={i18n[lang].fields.phoneNumber}
                value={serviceProvider.phonenumber!}
                type="phonenumber"
              />
            )}
            {serviceProvider.email && (
              <ContactInfo
                name={i18n[lang].fields.email}
                value={serviceProvider.email!}
                type="email"
              />
            )}
            {serviceProvider.webpage && (
              <ContactInfo
                name={i18n[lang].fields.webpage}
                value={serviceProvider.webpage!}
                type="link"
              />
            )}
            {serviceProvider.address && (
              <ContactInfo
                name={i18n[lang].fields.address}
                value={serviceProvider.address!}
                type="plain"
              />
            )}
          </div>
        </div>
        {serviceProvider.location && (
          <div>
            <p className="mx-4 font-bold">{i18n[lang].fields.map}</p>
            <Map
              latitude={serviceProvider.location.latitude}
              longitude={serviceProvider.location.longitude}
              placeName={serviceProviderName}
              address={serviceProvider.address as string | undefined}
            />
          </div>
        )}

        <div className="mx-4">
          <ServiceList services={services} lang={lang} />
        </div>
      </div>
      <GoBackButton />
      <FilterButton lang={lang} />
    </Layout>
  )
}

export default ServiceProvider
